'use client'

import { type MultiFactorError } from 'firebase/auth'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const AuthStatus = {
  UNAUTHENTICATED: 'unauthenticated',
  LOADING: 'loading',
  AUTHENTICATED: 'authenticated',
  MFA_TOTP_REQUIRED: 'mfaTotpRequired',
  MFA_UNREGISTERED: 'mfaUnregistered',
} as const satisfies Record<string, string>

interface AuthState {
  status: (typeof AuthStatus)[keyof typeof AuthStatus]
  mfaRequiredError: MultiFactorError | null
}

interface AuthStateAction {
  setStatus: (status: AuthState['status']) => void
  setMfaRequiredError: (error: AuthState['mfaRequiredError']) => void
  reset: () => void
}

export const useAuthStore = create<AuthState & AuthStateAction>()(
  persist(
    (set) => {
      const initialState: AuthState = {
        status: AuthStatus.UNAUTHENTICATED,
        mfaRequiredError: null,
      }

      return {
        ...initialState,
        setStatus: (status) => set({ status }),
        setMfaRequiredError: (error) => set({ mfaRequiredError: error }),
        reset: () => set(initialState),
      }
    },
    {
      name: 'authentication-state',
    }
  )
)
