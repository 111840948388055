'use client'

import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'
import { redirect } from 'next/navigation'

import { AppSplash } from '@/components'
import { ROUTES } from '@/lib/constants/routes'

export default function Root({
  params: { lang },
}: {
  params: {
    lang: string
  }
}) {
  const [user, loading, error] = useAuthState(getAuth())

  if (loading) return <AppSplash />

  if (error) throw error

  if (!user) {
    redirect(`/${lang}${ROUTES.LOGIN}`)
  }

  const tenantId = user.tenantId ?? ''
  return redirect(`/${lang}${ROUTES.TENANTS}/${tenantId}`)
}
