import { notify } from '@/components'
import { type ErrorCode, type ErrorResponse, type ForbiddenErrorResponse } from '@/types'

export const errorHandler = ({ error, apiStatus, apiError }: ErrorResponse) => {
  if (error) {
    console.error(error)
  }

  // 正常レスポンスの場合は何もしない
  if (apiStatus === undefined || apiStatus < 400) {
    return
  } else if (isForbiddenErrorResponse(apiError) && apiStatus === 403) {
    permissionErrorHandler(apiError?.code)
  } else {
    notify.error('エラーが発生しました')
  }
}

function isForbiddenErrorResponse(v: unknown): v is ForbiddenErrorResponse {
  return (
    (v as ForbiddenErrorResponse).code !== undefined &&
    (v as ForbiddenErrorResponse).message !== undefined
  )
}

function permissionErrorHandler(code: ErrorCode) {
  switch (code) {
    case 'PermissionError/TargetUserOperationNotAllowed':
    case 'PermissionError/ReadNotAllowed':
    case 'PermissionError/UpdateNotAllowed':
      notify.warning('この画面を閲覧・操作する権限がありません')
    default:
      break
  }
}
