'use client'

import { useQuery } from '@tanstack/react-query'

import { client } from '@/lib/api'
import { type UseQueryOptions } from '@/types'
import { type paths } from '@/types/schema'

export const GET_ACCOUNT = '/accounts/{fikaigoId}'

// アカウント詳細情報取得
export function useFetchAccount({
  params,
  reactQuery,
}: UseQueryOptions<paths[typeof GET_ACCOUNT]['get']>) {
  return useQuery({
    ...reactQuery,
    queryKey: [GET_ACCOUNT, params],
    queryFn: async ({ signal }) => {
      const {
        data,
        error,
        response: { status },
      } = await client.GET(GET_ACCOUNT, { params, signal })

      return {
        apiData: data,
        apiError: error,
        apiStatus: status,
      }
    },
  })
}
