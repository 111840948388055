'use client'

import { create } from 'zustand'

interface CacheControlState {
  facilityId: string
  businessFormId: string
  shiftGroupId: string
}

interface CacheControlStateAction {
  setFacilityId: (facilityId: string) => void
  setBusinessFormId: (businessFormId: string) => void
  setShiftGroupId: (shiftGroupId: string) => void
}

export const useCacheControlStore = create<CacheControlState & CacheControlStateAction>()((set) => {
  return {
    facilityId: '',
    setFacilityId: (facilityId: string) => set({ facilityId }),
    businessFormId: '',
    setBusinessFormId: (businessFormId: string) => set({ businessFormId }),
    shiftGroupId: '',
    setShiftGroupId: (shiftGroupId: string) => set({ shiftGroupId }),
  }
})
