'use client'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { client } from '@/lib/api'
import { useCacheControlStore } from '@/stores/useCacheControlStore'
import { type Account } from '@/types'

import { GET_ACCOUNT } from './useFetchAccount'
import { GET_ACCOUNTS } from './useFetchAccounts'

const DELETE_MFA = '/accounts/{fikaigoId}/mfa'

// MFA認証無効化
export function useDeleteMfa() {
  const queryClient = useQueryClient()
  const { facilityId } = useCacheControlStore()

  return useMutation({
    mutationFn: (fikaigoId: string) => {
      return client.DELETE(DELETE_MFA, { params: { path: { fikaigoId } } })
    },
    onMutate: async (fikaigoId) => {
      const previousUserInfo = queryClient.getQueryData<Account>([
        GET_ACCOUNT,
        { path: { fikaigoId } },
      ])

      const newUserInfo = {
        ...previousUserInfo,
        mfaConfig: { enabled: false, providers: null },
      }

      // fetchする前にキャッシュを更新
      queryClient.setQueryData([GET_ACCOUNT, { path: { fikaigoId } }], newUserInfo)

      return { previousUserInfo, newUserInfo }
    },
    onSettled: async (_data, _error, fikaigoId) => {
      // 並行でデータの再更新
      Promise.all([
        // アカウント詳細画面のバックグラウンド更新
        queryClient.invalidateQueries(
          {
            queryKey: [GET_ACCOUNT, { path: { fikaigoId } }],
            // 非アクティブなクエリも再fetchさせる
            refetchType: 'all',
          },
          { cancelRefetch: !!fikaigoId }
        ),
        // アカウント一覧画面のバックグラウンド更新
        queryClient.invalidateQueries(
          {
            queryKey: [GET_ACCOUNTS, facilityId],
            // 非アクティブなクエリも再fetchさせる
            refetchType: 'all',
          },
          { cancelRefetch: !!facilityId }
        ),
      ])
    },
  })
}
