'use client'

import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'

import { client } from '@/lib/api'
import { type Account, type ApiResponse, type UseQueryOptions } from '@/types'
import { type components, type paths } from '@/types/schema'

export const GET_ADMINISTRATORS = '/administrators'

// Custom Hooksの戻り値の型
export type UseFetchAdministrators = {
  data: UseQueryResult<ApiResponse<Account[]>, Error>
  reFetch: () => Promise<
    ({ list: components['schemas']['AuthAccount'][] } & { totalCount: number }) | undefined
  >
}

// 法人管理者アカウント取得
export function useFetchAdministrators({
  params,
  reactQuery,
}: UseQueryOptions<paths[typeof GET_ADMINISTRATORS]['get']>): UseFetchAdministrators {
  const queryClient = useQueryClient()

  const data = useQuery({
    ...reactQuery,
    queryKey: [GET_ADMINISTRATORS, params],
    queryFn: async ({ signal }) => {
      const {
        data: { list = [] } = {},
        error,
        response: { status },
      } = await client.GET(GET_ADMINISTRATORS, { params, signal })

      return {
        apiData: list,
        apiError: error,
        apiStatus: status,
      }
    },
    enabled: !!params,
  })

  const reFetch = async () => {
    const data = await queryClient.fetchQuery({
      queryKey: [GET_ADMINISTRATORS, params],
      queryFn: async ({ signal }) => {
        const { data } = await client.GET(GET_ADMINISTRATORS, { params, signal })
        return data
      },
    })
    return data
  }

  return { data, reFetch }
}
