'use client'

import { type FallbackNs } from 'react-i18next'
import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { type Namespace, type TFunction } from 'i18next'

import { client } from '@/lib/api'
import { YYYY_MM_DD } from '@/lib/constants/dateFormat'
import { type Account, type TableData, type UseQueryOptions } from '@/types'
import { type paths } from '@/types/schema'
import { isEmpty } from '@/utils'

export const GET_ACCOUNTS = '/accounts'

const limit = 100

// Custom Hooksの戻り値の型
export type UseFetchAccounts = {
  data: UseQueryResult<Account[], Error>
  reFetch: (
    currentFacilityId: string,
    currentBusinessFormId: string,
    currentShiftGroupId: string
  ) => Promise<({ list: Account[] } & { totalCount: number }) | undefined>
}

// アカウント詳細情報取得
export function useFetchAccounts({
  params,
  reactQuery,
}: UseQueryOptions<paths[typeof GET_ACCOUNTS]['get']>): UseFetchAccounts {
  const facilityId = params?.query?.facilityId ?? ''
  const queryClient = useQueryClient()

  const data = useQuery({
    ...reactQuery,
    queryKey: [GET_ACCOUNTS, facilityId],
    queryFn: async ({ signal }) => {
      const { data } = await client.GET(GET_ACCOUNTS, {
        params: {
          query: {
            facilityId: facilityId,
            limit: limit,
          },
        },
        signal,
      })
      return data
    },
    // テーブルデータに整形して返却
    select(accounts) {
      const { list = [] } = accounts || {}
      return list
    },
    enabled: !!facilityId,
  })

  const reFetch = async (
    currentFacilityId: string,
    currentBusinessFormId: string,
    currentShiftGroupId: string
  ) => {
    const queryFacilityId = isEmpty(currentFacilityId) ? undefined : currentFacilityId
    const queryBusinessFormId = isEmpty(currentBusinessFormId) ? undefined : currentBusinessFormId
    const queryShiftGroupId = isEmpty(currentShiftGroupId) ? undefined : currentShiftGroupId

    const data = await queryClient.fetchQuery({
      queryKey: [GET_ACCOUNTS, queryFacilityId, queryBusinessFormId, queryShiftGroupId],
      queryFn: async ({ signal }) => {
        const { data } = await client.GET(GET_ACCOUNTS, {
          params: {
            query: {
              facilityId: queryFacilityId,
              businessFormId: queryBusinessFormId,
              shiftGroupId: queryShiftGroupId,
              limit: limit,
            },
          },
          signal,
        })
        return data
      },
    })
    return data
  }

  return { data, reFetch }
}

// テーブルデータ変換
export function convertAccountsToTableData(
  accounts: Account[],
  facilityId: string,
  t: TFunction<FallbackNs<Namespace>, undefined>
): TableData[] {
  return accounts?.map((data) => {
    const {
      fikaigoId,
      displayName = '',
      mfaConfig: { providers = [], enrolledAt = '' },
      disabled,
    } = data

    return {
      fikaigoId,
      displayName: isEmpty(displayName) ? t('labels.notSet') : displayName,
      facilityId,
      disabled,
      enrolledAt:
        isEmpty(providers) || isEmpty(enrolledAt)
          ? '-'
          : `${providers?.join(', ').toUpperCase()} ${t('labels.registrationDate')}：${dayjs(enrolledAt).format(YYYY_MM_DD)}`,
    } as TableData
  })
}
