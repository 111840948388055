'use client'

import { type PropsWithChildren, type ReactNode, useEffect, useState } from 'react'
import { type Analytics, getAnalytics, isSupported } from 'firebase/analytics'
import { type FirebaseOptions, getApps, initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { type FirebasePerformance, getPerformance } from 'firebase/performance'

import { env } from '@/config/env'

declare global {
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
}

const firebaseOptions: FirebaseOptions = {
  apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
  // measurementId: env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

const app = !getApps().length ? initializeApp(firebaseOptions) : getApps()[0]

function FirebaseAppCheckProvider({ children }: { children: ReactNode }) {
  const siteKey = process.env.NEXT_PUBLIC_RE_CAPTCHA_ENTERPRISE_SITE_KEY

  useEffect(() => {
    if (!siteKey || !(typeof window !== 'undefined')) {
      return
    }

    if (env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
    }

    // scsk環境のみ環境変数に登録されているデバッグトークンを使用するようにする
    if (env.NEXT_PUBLIC_ENVIRONMENT === 'scsk') {
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = env.NEXT_PUBLIC_FIREBASE_APPCHECK_DEBUG_TOKEN
    }

    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(siteKey),
      isTokenAutoRefreshEnabled: true,
    })
  }, [siteKey])

  return children
}

function FirebasePerformanceProvider() {
  const [performance, setPerformance] = useState<FirebasePerformance | null>(null)

  useEffect(() => {
    isSupported()
      .then((_) => setPerformance(getPerformance(app)))
      .catch((e) =>
        console.warn('FirebasePerformance is not supported in this environment.', e.message)
      )
  }, [])

  if (performance) {
    // performance
  }

  return null
}

function FirebaseAnalyticsProvider() {
  const [analytics, setAnalytics] = useState<Analytics | null>(null)

  useEffect(() => {
    if ('measurementId' in app.options) {
      isSupported()
        .then((_) => setAnalytics(getAnalytics(app)))
        .catch((e) => console.warn('Analytics is not supported in this environment.', e.message))
    }
  }, [])

  if (analytics) {
    // analytics
  }

  return null
}

export function FirebaseServiceProviders({ children }: PropsWithChildren) {
  return (
    <>
      <FirebasePerformanceProvider />
      <FirebaseAnalyticsProvider />
      <FirebaseAppCheckProvider>{children}</FirebaseAppCheckProvider>
    </>
  )
}
