'use client'

import { type PropsWithChildren, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'
import { useRouter } from 'next/navigation'

import { AppSplash } from '@/components'
import { useHandleAuthState } from '@/hooks/useHandleAuthState'
import { ROUTES } from '@/lib/constants/routes'
import { AuthStatus } from '@/stores/useAuthStore'

export function PrivateWrapper({ children }: PropsWithChildren) {
  const router = useRouter()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [user, loading, error] = useAuthState(getAuth())
  const { status, reset } = useHandleAuthState()

  useEffect(() => {
    let isActive = true
    if (!isActive) return

    if (loading) return

    if (error) throw error

    switch (status) {
      case AuthStatus.LOADING:
        break
      case AuthStatus.UNAUTHENTICATED:
        if (!user) {
          reset()
          router.replace(ROUTES.LOGIN)
        }
        break
      case AuthStatus.MFA_UNREGISTERED:
        router.replace(ROUTES.MFA_REGISTER)
        break
      case AuthStatus.MFA_TOTP_REQUIRED:
        router.replace(ROUTES.MFA_AUTHENTICATION)
        break
      case AuthStatus.AUTHENTICATED:
        setIsAuthorized(true)
        break
      default:
        break
    }

    return () => {
      isActive = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, user, loading, error])

  if (status === AuthStatus.LOADING || status === AuthStatus.UNAUTHENTICATED || !isAuthorized) {
    return <AppSplash />
  }

  return children
}
