import { env } from '@/config/env'

export const ROUTES = {
  ROOT: '/',
  ACCOUNTS: '/accounts',
  TENANTS: '/tenants',
  ADMINISTRATORS: '/administrators',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  MFA_REGISTER: '/mfa/register',
  MFA_AUTHENTICATION: '/mfa/authentication',
  MFA_UNAVAILABLE_DEVICE: '/mfa/unavailable-device',
  SHIFT_APP: env.NEXT_PUBLIC_SHIFT_APP_URL,
} as const satisfies Record<string, string>
