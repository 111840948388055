import { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { FactorId, getAuth, multiFactor, type User } from 'firebase/auth'
import { useRouter } from 'next/navigation'

import { ROUTES } from '@/lib/constants/routes'
import { AuthStatus, useAuthStore } from '@/stores/useAuthStore'

/** 認証状態の制御を行う関数 */
export function useHandleAuthState() {
  const router = useRouter()

  const [user, loading, error] = useAuthState(getAuth())

  const { mfaRequiredError, status, reset, setStatus, setMfaRequiredError } = useAuthStore()

  useEffect(() => {
    let isActive = true
    if (!isActive) return

    if (loading) return

    if (error) {
      reset()
      return
    }

    // ユーザーが存在しない場合
    if (!user) {
      // MFA必須エラーが存在する場合は、MFA認証画面に遷移させる
      if (mfaRequiredError) {
        setStatus(AuthStatus.MFA_TOTP_REQUIRED)
        router.replace(ROUTES.MFA_AUTHENTICATION)
        return
      }

      // MFA必須エラーも存在しない場合は、未認証とする
      reset()
      router.replace(ROUTES.LOGIN)
      return
    }

    // 認証ステータスがMFA未登録、かつ、MFA必須エラーが存在しない場合は、MFA登録画面に遷移する
    if (status === AuthStatus.MFA_UNREGISTERED && !mfaRequiredError) {
      router.replace(ROUTES.MFA_REGISTER)
      return
    }

    // ユーザーオブジェクトが存在していて、MFA（TOTP）も登録されている場合に認証されているとみなす
    if (isMfaRegistered(user)) {
      setStatus(AuthStatus.AUTHENTICATED)
      return
    }

    // 上記のif文に合致しない条件は、認証されていないとみなす
    reset()

    return () => {
      isActive = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, error])

  return {
    status,
    mfaRequiredError,
    setStatus,
    setMfaRequiredError,
    reset,
  }
}

/** MFA登録（TOTP）がされているかどうかチェックする */
function isMfaRegistered(user: User): boolean {
  const enrolledFactors = multiFactor(user).enrolledFactors

  if (!enrolledFactors?.length) {
    return false
  }

  return enrolledFactors.some(({ factorId }) => factorId === FactorId.TOTP)
}
