'use client'

import { useQuery } from '@tanstack/react-query'

import { type Option } from '@/components'
import { client } from '@/lib/api'
import { type Facility, type UseQueryOptions } from '@/types'
import { type paths } from '@/types/schema'

const GET_FACILITIES = '/me/facilities'

// 施設情報取得
export function useFetchFacilities({
  reactQuery,
}: UseQueryOptions<paths[typeof GET_FACILITIES]['get']>) {
  return useQuery({
    ...reactQuery,
    queryKey: [GET_FACILITIES],
    queryFn: async ({ signal }) => {
      const {
        data: { list = [] } = {},
        error,
        response: { status },
      } = await client.GET(GET_FACILITIES, { signal })

      return {
        apiData: list,
        apiError: error,
        apiStatus: status,
      }
    },
  })
}

// Comboboxes用に型変換
export function convertFacilitiesToOptions(facilities: Facility[] = []): Option[] {
  return facilities.map(({ id, name }) => {
    return { label: name, value: id } as Option
  })
}
