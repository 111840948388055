'use client'

import Image from 'next/image'

import { Flex } from '@/components'

export function AppSplash() {
  return (
    <Flex align="center" className="h-screen w-screen bg-white" justify="center">
      <Image
        alt="Loading..."
        className="h-auto w-26 animate-splash"
        height={0}
        priority
        sizes="100vw"
        src="/logo.svg"
        width={0}
      />
    </Flex>
  )
}
