import { useEffect } from 'react'

import { notify } from '@/components'
import { type ErrorResponse } from '@/types'
import { errorHandler } from '@/utils/errors'

export function useErrorHandle({ error, apiError, apiStatus, isError = false }: ErrorResponse) {
  useEffect(() => {
    if (isError) {
      notify.error('エラーが発生しました')
    }
  }, [isError])

  useEffect(() => {
    if (apiStatus && (error || apiError)) {
      errorHandler({ error, apiError, apiStatus })
    }
  }, [error, apiError, apiStatus])
}
