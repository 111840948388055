import Image from 'next/image'

export function FikaigoTextLogo() {
  return (
    <Image
      alt="logo"
      height={0}
      sizes="100vw"
      src="/logo-text.svg"
      style={{
        height: 30,
        width: 'auto',
      }}
      width={0}
    />
  )
}
